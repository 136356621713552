<template>
    <div class="author">
        <router-link :to="link">
            <avatar :src="avatar"/>
        </router-link>
        <div class="author__meta">
            <router-link :to="link" class="author__fullname">{{fullName}}</router-link>
            <span :key="timeKey" class="author__date">
                {{ dateOutput.local().format('DD.MM.YYYY HH:mm') }}
            </span>
        </div>
    </div>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: "Author",
        components: {
            Avatar : () => import('@/components/Avatar')
        },
        computed: {
            ...mapState('default_data', [
                'time'
            ])
        },
        props: {
            avatar: {
                type: String,
                required: false,
                default: ''
            },
            fullName: {
                type: String,
                required: true
            },
            link: {
                type: String,
                required: true
            },
            date: {
                type: String,
                required: false,
                default: ''
            },
            timeKey: {
                type: Number,
                required: false,
                default: new Date().getTime()
            }
        },
        data() {
            return {
                dateOutput: this.$moment.utc(this.date)
            }
        },
        mounted() {
            const statusDate = this.$moment.utc(this.date).local().valueOf() > new Date().getTime()
            this.dateOutput = statusDate ? this.$moment(this.date) : this.dateOutput
        }
    }
</script>

<style lang="sass" scoped>
    @import "#sass/v-style"

    .author
        display: flex
        align-items: flex-start
        &__meta
            display: flex
            flex-direction: column
            margin-left: 15px
        &__fullname
            font-size: 1rem
            font-weight: 600
            color: #000
        &__date
            font-size: 1rem
            color: $text-gray
</style>
